<template>
  <div class="admin-customers-listing">
    <title-bar addBtn :addUrl="getAddCustomerURL()" title="Customers" />
    <search class="mb-4" @inputChange="inputChange" />
    <vue-good-table
      class="custom-table-style"
      mode="remote"
      :totalRows="pages"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="customers"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span :class="props.column.field" v-if="props.column.field == 'name'">
          <strong>{{ `${props.row.givenName ? props.row.givenName : '' } ${props.row.familyName ? props.row.familyName : ''}` }}</strong>
        </span>

        <span
          class="d-flex align-items-center"
          :class="props.column.field"
          v-else-if="props.column.field == 'telephone'"
        >
          {{ props.formattedRow[props.column.field] }}
          <call-btn
            class="ml-2"
            v-if="props.formattedRow[props.column.field]"
            :number="props.formattedRow[props.column.field]"
          />
        </span>

        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'address'"
        >
         {{ `${props.formattedRow[props.column.field].streetAddress} ${props.formattedRow[props.column.field].streetNumber ? props.formattedRow[props.column.field].streetNumber : ''}` }}
        </span>

        <span class="buttons" v-else-if="props.column.field == 'id'">
          <div class="just-buttons">
            <view-btn
              :pathUrl="
                getDetailCustomerURL(props.formattedRow[props.column.field])
              "
            />
            <edit-btn
              :path="getEditCustomerURL(props.formattedRow[props.column.field])"
            />
            <delete-btn @pressDelete="deleteCustomer(props.row['@id'])" />
          </div>
        </span>
        <span :class="props.column.field" v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import Search from '@/components/Inputs/Search.vue'
import CallBtn from '@/components/Buttons/CallBtn.vue'
import ViewBtn from '@/components/Buttons/ViewBtn.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import Toast from '@/components/Toast.vue'

export default {
  name: 'AdminCustomersListing',
  components: {
    TitleBar,
    Search,
    VueGoodTable,
    TablePagination,
    CallBtn,
    EditBtn,
    DeleteBtn,
    ViewBtn,
  },
  data() {
    return {
      search: '',
      isLoading: false,
      customers: null,
      perPage: 15,
      currentPage: 1,
      pages: 0,

      columns: [
        {
          field: 'name',
          sortable: false,
        },
        {
          field: 'telephone',
          sortable: false,
        },
        {
          field: 'email',
          sortable: false,
        },
        {
          field: 'address',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
        },
      ],
    }
  },
  created() {
    // this.loadCustomers() // netreba, pri loade sa zavola onPageChange
  },
  methods: {
    loadCustomers() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        role: ['ROLE_CLIENT'],
      }
      if (this.search && this.search.length) {
        params.search = this.search
      }
      this.$Users.getCollection({ params }, 'customer_list').then(response => {
        this.pages = response.data['hydra:totalItems']
        this.customers = response.data['hydra:member']
      })
    },
    deleteCustomer(url) {
      this.$Users
        .deleteResourceByUrl({ url })
        .then(response => {
          if (response.status === 204) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Customer deleted',
              '',
              'success',
            )
            this.loadCustomers()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$emit('clearAction')
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Failed to delete',
              '',
              'danger',
            )
          }
        })
    },
    inputChange(value) {
      this.search = value
      this.loadCustomers()
    },
    onPageChange() {
      this.loadCustomers()
    },
    getAddCustomerURL() {
      return `/add-customer`
    },
    getDetailCustomerURL(id) {
      return `/customers/${id}`
    },
    getEditCustomerURL(id) {
      return `/customers/${id}/edit`
    },
  },
}
</script>
