<template>
  <router-link :to="pathUrl" class="custom-btn view-btn">
    <img src="@/assets/images/icons/eye.svg" alt="eye" />
  </router-link>
</template>

<script>
export default {
  name: 'ViewBtn',
  props: {
    pathUrl: String,
  },
}
</script>
